/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useRef, useState } from "react";

import { GoogleMap } from "@react-google-maps/api";
import clsx from "clsx";

import {
  CountrySelect,
  Items,
  LandmarkSelect,
  LeftHeader,
  LocationFeeMarker,
  NurseDetail,
  Nurses,
  Polygon,
  RightHeader,
  Upserter,
} from "~/components/Sectors";
import nurseService from "~/utils/api/v1/nurseService";
import sectorsAPI from "~/utils/api/v2/places";
import { LOCALITY_CONFIG } from "~/utils/constants/sectors";
import type { Locale } from "~/utils/interfaces/Locale";
import type { Nurse } from "~/utils/interfaces/Nurse";
import type { Sector } from "~/utils/interfaces/Places";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const SectorsPage = () => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [allNurses, setAllNurses] = useState<Nurse[]>([]);
  const [selectedSector, setSelectedSector] = useState<Sector | null>(null);
  const [selectedSectorId, setSelectedSectorId] = useState<string | null>(null);
  const [hoveredOverSectorId, setHoveredOverSectorId] = useState<string | null>(null);
  const [selectedNurseId, setSelectedNurseId] = useState<string | null>(null);
  const [showAllNurseSectors, setShowAllNurseSectors] = useState<boolean>(false);
  const [highlightedSectorsIds, setHighlightedSectorsIds] = useState<string[]>([]);
  const [country, setCountry] = useState<Locale | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<string>("Capital");

  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

  const resetHighlights = () => {
    setShowAllNurseSectors(false);
    setHighlightedSectorsIds([]);
  };

  const reset = () => {
    setSelectedSector(null);
    setSelectedNurseId(null);
    setSelectedSectorId(null);
    resetHighlights();
  };

  const handleOnSelectSector = (id: string) => {
    setSelectedSectorId(id);
    const foundSector = sectors.find((sector) => sector.id === id);
    setSelectedSector(foundSector || null);
  };

  const handleOnSelectNurse = (id: string) => {
    if (selectedNurseId === id) {
      setSelectedNurseId(null);
    } else {
      setSelectedNurseId(id);
      scrollableContainerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleHideSectorFee = (sectorId: string) => {
    if (selectedSector) {
      return selectedSector.id !== sectorId;
    }
    return hoveredOverSectorId !== sectorId;
  };

  const fetchSectors = useCallback(async () => {
    if (!country) return;
    const res = await sectorsAPI.list(country, selectedRegion === "Operativos");
    if (res) {
      setSectors(res);
    }
  }, [country, selectedRegion]);

  useEffect(() => {
    if (selectedNurseId === null && showAllNurseSectors) resetHighlights();
  }, [showAllNurseSectors, selectedNurseId]);

  useEffect(() => {
    if (showAllNurseSectors && selectedNurseId && map && country) {
      const fetchNurseSectors = async () => {
        const nurseSectors = await sectorsAPI.fetchNurseSectors(selectedNurseId);
        if (nurseSectors) {
          map.setZoom(LOCALITY_CONFIG[country][selectedRegion].zoom);
          map.setCenter(LOCALITY_CONFIG[country][selectedRegion].center);
          setHighlightedSectorsIds(nurseSectors.map((sector) => sector.id));
        }
      };
      fetchNurseSectors();
    }
  }, [showAllNurseSectors, selectedNurseId, map, country]);

  useEffect(() => {
    if (!country) return;
    fetchSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  useEffect(() => {
    // Handle country change
    if (!country) return;

    const fetchAllNurses = async () => {
      const operativeFilter = selectedRegion === "Operativos" ? "operative" : "regular";
      const activeRes = await nurseService.fetchActiveNurses(country, operativeFilter);
      const inactiveRes = await nurseService.fetchInactiveNurses(country, operativeFilter);
      let allNurses: Nurse[] = [];
      if (activeRes) {
        allNurses = allNurses.concat(activeRes.data.data);
      }
      if (inactiveRes) {
        allNurses = allNurses.concat(inactiveRes.data.data);
      }
      setAllNurses(allNurses);
    };
    fetchAllNurses();
    fetchSectors();
    reset();
  }, [country, selectedRegion]);

  if (!country) return <CountrySelect onSetCountry={(country: Locale) => setCountry(country)} />;

  return (
    <div className="w-full grid grid-cols-12 gap-x-3">
      <div className="h-[calc(100vh_-_20rem)] w-full col-span-5">
        <LeftHeader
          country={country}
          sector={selectedSector}
          onSetCountry={(country: Locale) => setCountry(country)}
        />
        {/* @ts-expect-error */}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={LOCALITY_CONFIG[country][selectedRegion].center}
          zoom={LOCALITY_CONFIG[country][selectedRegion].zoom}
          onLoad={(map) => setMap(map)}
        >
          {sectors?.map((sector) => (
            <div key={sector.id}>
              <Polygon
                key={`sector-${sector.id}`}
                sector={sector}
                selectedId={selectedSectorId}
                country={country}
                selectedRegion={selectedRegion}
                isHighlighted={highlightedSectorsIds.includes(sector.id)}
                forceHighlight={showAllNurseSectors}
                onSelect={handleOnSelectSector}
                onMouseOverCallback={(sectorId) => setHoveredOverSectorId(sectorId)}
              />
              <LocationFeeMarker
                key={`fee-marker-${sector.id}`}
                sectorId={sector.id}
                centroid={sector.centroid}
                hide={handleHideSectorFee(sector.id)}
                fee={sector.location_fee}
                minimal={!selectedSectorId}
                fetchSectorsCallback={fetchSectors}
              />
            </div>
          ))}
        </GoogleMap>
        <Upserter
          country={country}
          fetchSectorsCallback={fetchSectors}
        />
      </div>
      <div
        className={clsx(
          "relative",
          "h-[calc(100vh_-_13rem)] w-full px-10 pb-5",
          "col-span-7 flex flex-col gap-y-10",
          "overflow-y-scroll",
        )}
      >
        <LandmarkSelect
          country={country}
          currentLandmark={selectedRegion}
          onSetLandmark={(landmark: string) => setSelectedRegion(landmark)}
        />
        {!selectedSector && (
          <h2 className="absolute w-full top-1/2 -translate-y-1/2 transform text-center font-normal">
            Selecciona un sector para administrarlo 🧐
          </h2>
        )}
        {!!selectedSector && (
          <>
            <RightHeader sector={selectedSector} />
            <Nurses
              allNurses={allNurses}
              selectedSectorId={selectedSectorId}
              selectedNurseId={selectedNurseId}
              onSelectNurse={handleOnSelectNurse}
            />
            <NurseDetail
              nurse={allNurses.find((nurse) => nurse.id === selectedNurseId) || null}
              country={country}
              onToggleShowAllNurseSectors={() => setShowAllNurseSectors(!showAllNurseSectors)}
            />
            {selectedSectorId && <Items selecteSectorId={selectedSectorId} />}
          </>
        )}
        <div ref={scrollableContainerRef} />
      </div>
    </div>
  );
};

export default SectorsPage;
