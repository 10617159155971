import { camelizeKeys, decamelizeKeys } from "humps";
import qs from "qs";

import api from "~/utils/api/api";
import type { Locale } from "~/utils/interfaces/Locale";
import type { Nurse } from "~/utils/interfaces/Nurse";
import type { Sector, SectorPayload, Coordinate } from "~/utils/interfaces/Places";
import { BlockedItemsSector } from "~/utils/interfaces/Timeblock";

class SectorsAPI {
  list = async (country: Locale, isOperative?: boolean): Promise<Sector[] | undefined> => {
    try {
      const res = await api.get<Sector[]>(`v2/sector/`, {
        params: decamelizeKeys({ country, isOperative }),
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  updateSector = async (sectorId: string, data: Partial<Sector>): Promise<Sector | undefined> => {
    try {
      const res = await api.patch<Sector>(`v2/sector/${sectorId}/`, data);
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  fetchSectorNurses = async (sectorId: string): Promise<Nurse[] | undefined> => {
    try {
      const res = await api.get<Nurse[]>(`v2/sector/${sectorId}/sector-nurses/`);
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  fetchNurseSectors = async (nurseId: string): Promise<Sector[] | undefined> => {
    try {
      const res = await api.get<Sector[]>(`v2/sector/nurse-sectors/`, {
        params: { nurse_id: nurseId },
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  assignNurseToSector = async (sectorId: string, nurseId: string): Promise<{ msg: string } | undefined> => {
    try {
      const res = await api.post<{ msg: string }>(`v2/nurse-sector-timeblocks/assign/`, {
        sector_id: sectorId,
        nurse_id: nurseId,
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  unassignNurseToSector = async (sectorId: string, nurseId: string): Promise<{ msg: string } | undefined> => {
    try {
      const res = await api.post<{ msg: string }>(`v2/nurse-sector-timeblocks/unassign/`, {
        sector_id: sectorId,
        nurse_id: nurseId,
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  manualUpsert = async (data: FormData): Promise<{ msg: string } | undefined> => {
    try {
      const res = await api.post<{ msg: string }>(`v2/sector/manual-upsert/`, data);
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  forceUpsert = async (country: Locale): Promise<{ msg: string } | undefined> => {
    try {
      const res = await api.post<{ msg: string }>(`v2/sector/force-upsert/`, { country });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  blockItemSector = async (sectorId: string, itemId: string): Promise<any> => {
    try {
      const res = await api.post(`v2/blocked-item-sector/`, {
        sector: sectorId,
        item: itemId,
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  unblockItemSector = async (sectorId: string, itemId: string): Promise<any> => {
    try {
      const res = await api.post(`v2/blocked-item-sector/remove-blockage/`, {
        sector_id: sectorId,
        item_id: itemId,
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  getBlockedItemsSector = async ({
    countryCode,
    sectorId,
  }: {
    countryCode: string;
    sectorId: string | null;
  }): Promise<BlockedItemsSector[]> => {
    try {
      const data = decamelizeKeys({ countryCode, sectorId });
      const res = await api.get<BlockedItemsSector[]>(`v2/blocked-item-sector/?${qs.stringify(data)}`);
      return camelizeKeys(res.data) as BlockedItemsSector[];
    } catch (error) {
      return [];
    }
  };

  getSectorFromCoordinates = async (coordinates: Coordinate, country?: Locale): Promise<SectorPayload | undefined> => {
    try {
      const res = await api.get<SectorPayload>(
        `/v2/sector/sector-from-coordinates/?${qs.stringify({
          ...coordinates,
          country,
        })}`,
      );
      return res.data;
    } catch (err) {
      return undefined;
    }
  };
}

const sectorsAPI = new SectorsAPI();

export default sectorsAPI;
